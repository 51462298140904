import React from "react";
import DownloadFileButton from "./DownloadFileButton";
import ViewFileButton from "./ViewFileButton";
import FormattedDate from "../../shared/components/util/FormattedDate";
import ContinueDraft from "./ContinueDraft";
import DeleteButton from "./DeleteButton";
import { Link } from "react-router-dom";

const FileItem = ({ file, fileType, status = "final" }) => {
	let title;

	if (fileType === "contract") {
		if (file.info.werknemerVoorletters && file.info.werknemerAchternaam) {
			title =
				file.info.werknemerVoorletters +
				" " +
				file.info.werknemerAchternaam;
		} else {
			title = "Nieuwe medewerker";
		}
	} else if (fileType === "personeelsreglement") {
		if (file.info.versie !== undefined) {
			title = "Versie " + file.info.versie;
		} else {
			title = "Nieuwe versie";
		}
	} else if (fileType === "ovo") {
		title = file.info.naamZZP;
	} else if (fileType === "studie") {
		title = `${file.info.medNaam} - ${file.info.studieInstituut}`;
	}

	return (
		<div className="flex py-5">
			<div className="w-full lg:w-1/2 pr-6 lg:pr-0 hover:opacity-60 duration-200 transition-all">
				<Link
					to={
						status === "final"
							? `/${fileType}/${file._id}`
							: `/nieuw/${fileType}/?draft=${file._id}`
					}
				>
					<h4 className="text-md mb-1 leading-6 font-medium">
						{title}
					</h4>
					<p className="text-gray-500 text-sm font-normal">
						<FormattedDate
							date={file.dateCreated}
							verb={status === "draft" && "begonnen"}
						/>
					</p>
				</Link>
			</div>
			<div className="w-full lg:w-1/2 flex align-middle items-center justify-end">
				{status === "draft" && (
					<>
						<DeleteButton
							fileID={file._id}
							fileType={fileType}
							iconOnly
							classNames="mr-4 border-r border-gray-300 pr-2"
						/>
						<ContinueDraft fileID={file._id} fileType={fileType} />
					</>
				)}
				{status === "final" && (
					<>
						<ViewFileButton
							fileID={file._id}
							fileType={fileType}
							className="mr-8"
						/>
						<DownloadFileButton
							fileID={file._id}
							fileType={fileType}
						/>
					</>
				)}
			</div>
		</div>
	);
};

export default FileItem;
