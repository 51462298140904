/* eslint-disable default-case */
import React, { useEffect, useState, useCallback, useRef } from "react";

import { useUser } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";

import { Form, Formik } from "formik";
import debounce from "lodash/debounce";

import useFetch from "../../shared/hooks/useFetch";
import { shouldDisplayField } from "../utils/form-utils";
import fileDictionary from "../../fileDictionary";

import FormValidator from "./FormValidator";
import FieldRenderer from "./FieldRenderer";
import FormActions from "./FormActions";

const DynamicForm = ({
	steps,
	currentStep,
	setCurrentStep,
	initialValues,
	fileType,
	setFilledFields,
	draftId,
	setDraftId,
	setLastUpdated,
	setLoading,
	formIsCompleted,
}) => {
	const [values, setValues] = useState({});
	const [hasChanges, setHasChanges] = useState(false);

	const draftIdRef = useRef(draftId);
	const valuesRef = useRef();
	const postFile = useFetch();
	const { user } = useUser();
	const navigate = useNavigate();

	const apiEndpoint = fileDictionary[fileType].endpoint;

	const createDraft = async () => {
		const response = await postFile(`${apiEndpoint}/draft`, {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				creatorId: user.id,
				info: values,
			}),
		});
		setDraftId(response.id);

		// Update the URL without refreshing the page
		const newUrl = `${window.location.origin}${window.location.pathname}?draft=${response.id}`;
		window.history.pushState({ path: newUrl }, "", newUrl);
	};

	const updateDraft = async (currentValues) => {
		if (draftIdRef.current) {
			const response = await postFile(
				`${apiEndpoint}/draft/${draftIdRef.current}`,
				{
					method: "PATCH",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify({
						info: currentValues,
					}),
				}
			);
			setLastUpdated(response.lastUpdated);
		}
	};

	const updateUser = async (currentValues) => {
		user.update({
			unsafeMetadata: {
				initialPraktijkNaam: user.unsafeMetadata.initialPraktijkNaam,
				werkgeverAanhef: currentValues.werkgeverAanhef,
				werkgeverVoorletters: currentValues.werkgeverVoorletters,
				werkgeverAchternaam: currentValues.werkgeverAchternaam,
				praktijkNaam: currentValues.praktijkNaam,
				praktijkAdres: currentValues.praktijkAdres,
				praktijkPostcode: currentValues.praktijkPostcode,
				praktijkPlaats: currentValues.praktijkPlaats,
				standplaats: currentValues.standplaats,
				reglementVersie: currentValues.reglementVersie,
				reglementJaartal: currentValues.reglementJaartal,
				medewerkersLoondienst: currentValues.medewerkersLoondienst,
				medewerkersTotaal: currentValues.medewerkersTotaal,
				praktijkhoudersNaam: currentValues.praktijkhoudersNaam,
				kvk: currentValues.kvk,
				praktijkVorm: currentValues.praktijkVorm,
				holding: currentValues.holding,
				bvHolding: currentValues.bvHolding,
				eigenaarNaam: currentValues.eigenaarNaam,
				ogWonen: currentValues.ogWonen,
				praktijkStraat: currentValues.praktijkStraat,
				maten: currentValues.maten,
			},
		});
	};

	const createDocument = async (values) => {
		setLoading(true);
		const displayableValues = Object.keys(values).reduce((acc, key) => {
			const field = steps
				.flatMap((step) => step.fields)
				.find((field) => field.name === key);
			if (field && shouldDisplayField(field.condition, values)) {
				acc[key] = values[key];
			}
			return acc;
		}, {});

		updateUser(displayableValues);
		const formData = {
			info: displayableValues,
			creatorId: user.id,
		};

		const response = await postFile(
			`${apiEndpoint}/final/${draftIdRef.current}`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(formData),
			}
		);

		console.log(
			`/${fileType}/${response.id}/?netGemaakt=ja&vraagReview=ja`
		);

		setLoading(false);
		navigate(`/${fileType}/${response.id}/?netGemaakt=ja&vraagReview=ja`);
	};

	const debouncedUpdateDraft = useCallback(debounce(updateDraft, 6000), []);

	useEffect(() => {
		valuesRef.current = values;
	}, [values]);

	useEffect(() => {
		draftIdRef.current = draftId;
	}, [draftId]);

	useEffect(() => {
		if (hasChanges) {
			if (!draftIdRef.current) {
				createDraft();
			} else {
				debouncedUpdateDraft(values);
			}
			setHasChanges(false);
		}
	}, [hasChanges]);

	return (
		<Formik
			key={JSON.stringify(initialValues)}
			initialValues={initialValues || {}}
			onSubmit={(values) => createDocument(values)}
		>
			{(formik) => (
				<>
					<FormValidator
						formik={formik}
						setValues={setFilledFields}
					/>
					<Form
						onBlur={() => {
							if (!hasChanges) {
								setHasChanges(true);
							}
							setValues(formik.values);
						}}
					>
						<div className="flex flex-wrap -mx-2 denthr-form">
							{steps[currentStep - 1] &&
								steps[currentStep - 1].fields.map((field) => {
									return (
										<FieldRenderer
											field={field}
											values={formik.values}
											userId={user.id}
											fileType={fileType}
										/>
									);
								})}
						</div>

						<FormActions
							currentStep={currentStep}
							setCurrentStep={setCurrentStep}
							steps={steps}
							formIsCompleted={formIsCompleted}
						/>
					</Form>
				</>
			)}
		</Formik>
	);
};

export default DynamicForm;
