const fileDictionary = {
	contract: {
		endpoint: "/api/contracten",
		singular: "contract",
		plural: "contracten",
		newButtonText: "Nieuw contract",
	},
	ovo: {
		endpoint: "/api/ovo",
		singular: "overeenkomst van opdracht",
		plural: "overeenkomsten van opdracht",
		newButtonText: "Nieuwe OVO",
	},
	personeelsreglement: {
		endpoint: "/api/reglementen",
		singular: "personeelsreglement",
		plural: "personeelsreglementen",
		newButtonText: "Nieuw personeelsreglement",
	},
	studie: {
		endpoint: "/api/studie",
		singular: "studieovereenkomst",
		plural: "studieovereenkomsten",
		newButtonText: "Nieuwe studieovereenkomst",
	},
};

module.exports = fileDictionary;