import React, { useState, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useUser } from "@clerk/clerk-react";

import useFetch from "../../shared/hooks/useFetch";
import fileDictionary from "../../fileDictionary";

import BackButton from "../../shared/components/Navigation/BackButton";
import DynamicForm from "../components/DynamicForm";
import StepsOverview from "../components/StepsOverview";

import MainLogo from "../../shared/components/Branding/MainLogo";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import FormattedDate from "../../shared/components/util/FormattedDate";
import FormTip from "../components/FormTip";
import LoadingForm from "./LoadingForm";

const Wizard = () => {
	const [initialValues, setInitialValues] = useState({});
	const [formJSON, setFormJSON] = useState([]);
	const [currentStep, setCurrentStep] = useState(1);
	const [filledFields, setFilledFields] = useState({});

	const [draftId, setDraftId] = useState(null);
	const [lastUpdated, setLastUpdated] = useState(null);

	const [loadingDraft, setLoadingDraft] = useState(true);
	const [loadingSubmission, setLoadingSubmission] = useState(false);
	const [loadingForm, setLoadingForm] = useState(true);
	const [formIsCompleted, setFormIsCompleted] = useState(false);

	const { fileType } = useParams();
	const { user } = useUser();
	const location = useLocation();
	const fetchForm = useFetch();

	const searchParams = new URLSearchParams(location.search);

	const duration = searchParams.get("duur");
	const draftParams = searchParams.get("draft");
	const copyId = searchParams.get("copy");
	const stepParams = searchParams.get("stap");

	useEffect(() => {
		const knownFields = user.unsafeMetadata;
		setInitialValues((prevValues) => ({
			...prevValues,
			...knownFields,
		}));
	}, [user.unsafeMetadata]);

	useEffect(() => {
		if (fileType === "contract") {
			setInitialValues((prevValues) => ({
				...prevValues,
				duurContract: duration,
			}));
		}
	}, [fileType, duration]);

	useEffect(() => {
		const fetchFile = async () => {
			const data = await fetchForm(`/api/forms/${fileType}`);
			setFormJSON(data.steps);
			setLoadingForm(false);
		};

		fetchFile();
	}, [fetchForm, fileType]);

	useEffect(() => {
		if (copyId) {
			const fetchCopyData = async () => {
				try {
					const data = await fetchForm(
						`${fileDictionary[fileType].endpoint}/copy/${copyId}`
					);
					setInitialValues(data);
				} catch (error) {
					console.error("Error fetching copy data:", error);
				}
			};

			fetchCopyData();
		}
	}, [copyId, fetchForm, fileType]);

	useEffect(() => {
		setDraftId(draftParams);
	}, [draftParams]);

	useEffect(() => {
		if (stepParams) {
			setCurrentStep(stepParams);
		}
	}, [stepParams]);

	useEffect(() => {
		// set the stap url parameter to the current step
		const url = new URL(window.location);
		url.searchParams.set("stap", currentStep);
		window.history.replaceState({}, "", url);
	}, [currentStep]);

	useEffect(() => {
		const fetchDraftData = async () => {
			if (draftParams) {
				try {
					const data = await fetchForm(
						`${fileDictionary[fileType].endpoint}/draft/${draftParams}`
					);
					setInitialValues(data.values);
				} catch (error) {
					console.error("Error fetching draft data:", error);
				}
			}
			setLoadingDraft(false);
		};

		fetchDraftData();
	}, [draftParams, fetchForm, fileType]);

	return (
		<div
			className="bg-slate-100 min-h-screen pb-20"
			transparent
			paddingTop={false}
		>
			<div className="max-w-7xl mx-auto px-4 sm:px-6 ">
				<div className="flex justify-between pb-6 pt-14">
					<BackButton fileType={fileType} />
					<Link to="/">
						<MainLogo />
					</Link>

					<span> </span>
				</div>
				{loadingForm && <LoadingSpinner className="mt-20" />}
				{formJSON.length === 0 ||
					(loadingDraft && <LoadingSpinner className="mt-20" />)}
				{formJSON.length !== 0 && (
					<div className="flex lg:flex-nowrap flex-wrap flex-row items-start mt-8">
						<StepsOverview
							steps={formJSON}
							currentStep={currentStep}
							setCurrentStep={setCurrentStep}
							filledFields={filledFields}
							setFormIsCompleted={setFormIsCompleted}
						/>
						<div className="flex lg:flex-nowrap flex-wrap lg:basis-4/5 basis-full bg-white shadow-sm rounded-3xl lg:p-16 p-10">
							<div className="w-full">
								{formJSON[currentStep - 1] && (
									<h1 className="text-2xl font-bold">
										{formJSON[currentStep - 1].title}
									</h1>
								)}
								{formJSON[currentStep - 1] && (
									<p className="text-md leading-5 text-gray-500 mt-2 mb-2">
										{formJSON[currentStep - 1].description}
									</p>
								)}
								<DynamicForm
									fileType={fileType}
									steps={formJSON}
									currentStep={currentStep}
									setCurrentStep={setCurrentStep}
									initialValues={initialValues}
									setFilledFields={setFilledFields}
									draftId={draftId}
									setDraftId={setDraftId}
									setLastUpdated={setLastUpdated}
									setLoading={setLoadingSubmission}
									formIsCompleted={formIsCompleted}
								/>
							</div>
							{formJSON[currentStep - 1] &&
								formJSON[currentStep - 1].tip &&
								formJSON[currentStep - 1].tip.title !== "" && (
									<FormTip
										title={
											formJSON[currentStep - 1].tip.title
										}
										description={
											formJSON[currentStep - 1].tip
												.description
										}
										extraInfo={
											formJSON[currentStep - 1].tip
												.extraInfo
										}
									/>
								)}
						</div>
					</div>
				)}
				<p className="center text-gray-400 pt-12">
					<a
						href="https://dentiva.nl/denthr-disclaimer/"
						className="border-b-2 border-gray-300 hover:text-gray-500 hover:border-gray-400 transition-colors duration-200 ease-in-out"
						target="_blank"
						rel="noreferrer"
					>
						Disclaimer
					</a>
					{lastUpdated && (
						<>
							<span className="font-black mx-2 text-gray-400">
								·
							</span>
							<FormattedDate
								date={lastUpdated}
								verb="opgeslagen"
							/>
						</>
					)}
				</p>
			</div>
		</div>
	);
};

export default Wizard;
